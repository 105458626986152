exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-benjamin-hotels-tsx": () => import("./../../../src/pages/benjamin-hotels.tsx" /* webpackChunkName: "component---src-pages-benjamin-hotels-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-explore-kingston-tsx": () => import("./../../../src/pages/explore-kingston.tsx" /* webpackChunkName: "component---src-pages-explore-kingston-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lofts-king-premium-tsx": () => import("./../../../src/pages/lofts/king-premium.tsx" /* webpackChunkName: "component---src-pages-lofts-king-premium-tsx" */),
  "component---src-pages-lofts-king-tsx": () => import("./../../../src/pages/lofts/king.tsx" /* webpackChunkName: "component---src-pages-lofts-king-tsx" */),
  "component---src-pages-lofts-queen-tsx": () => import("./../../../src/pages/lofts/queen.tsx" /* webpackChunkName: "component---src-pages-lofts-queen-tsx" */),
  "component---src-pages-lofts-tsx": () => import("./../../../src/pages/lofts.tsx" /* webpackChunkName: "component---src-pages-lofts-tsx" */),
  "component---src-pages-lofts-two-bedroom-premium-terrace-tsx": () => import("./../../../src/pages/lofts/two-bedroom-premium-terrace.tsx" /* webpackChunkName: "component---src-pages-lofts-two-bedroom-premium-terrace-tsx" */),
  "component---src-pages-lofts-two-bedroom-premium-tsx": () => import("./../../../src/pages/lofts/two-bedroom-premium.tsx" /* webpackChunkName: "component---src-pages-lofts-two-bedroom-premium-tsx" */),
  "component---src-pages-offers-tsx": () => import("./../../../src/pages/offers.tsx" /* webpackChunkName: "component---src-pages-offers-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reserve-tsx": () => import("./../../../src/pages/reserve.tsx" /* webpackChunkName: "component---src-pages-reserve-tsx" */),
  "component---src-pages-smith-hotel-experience-tsx": () => import("./../../../src/pages/smith-hotel-experience.tsx" /* webpackChunkName: "component---src-pages-smith-hotel-experience-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-work-and-events-tsx": () => import("./../../../src/pages/work-and-events.tsx" /* webpackChunkName: "component---src-pages-work-and-events-tsx" */)
}

